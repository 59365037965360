import { ResponsiveBar } from "@nivo/bar";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import DashboardChartModal from "../DashboardChartModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const DashboardBarChart = ({
  sx,
  title,
  metricIsLoading,
  countTickets,
  parameters,
  fetchData,
  tableData,
  tableHeadCells,
  tableKeys,
  isLoading,
  isDataEmpty,
  data,
  keys,
  valueFormat,
  colors,
  label,
  labelSkipHeight,
  legends,
  tooltip,
  noDataTitle,
  style,
  open,
  handleClose,
  handleExport,
  exportIsLoading,
}) => {
  const chartProps = {
    title,
    isLoading,
    data,
    keys,
    valueFormat,
    colors,
    label,
    labelSkipHeight,
    legends,
    tooltip,
    style,
  };

  const tableProps = {
    metricIsLoading,
    countTickets,
    parameters,
    fetchData,
    tableData,
    tableHeadCells,
    tableKeys,
    handleExport,
    exportIsLoading,
  };

  const numberOfColumns = data.length;
  const basePadding = 0.2;
  const dynamicPadding = numberOfColumns > 3 ? basePadding : 0.6;
  return (
    <Box sx={sx}>
      <Card
        variant="outlined"
        sx={{
          borderRadius: "0.5rem",
          border: "1px solid #f3ebff",
          height: "100%",
        }}
      >
        <CardContent>
          <Typography
            component="div"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography component="div" sx={{ fontWeight: 600, fontSize: 18 }}>
              {title}
            </Typography>
            {open ? (
              <Box
                component="div"
                sx={{
                  width: "2rem",
                  height: "2rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid rgba(0, 0, 0, 0.20)",
                  borderRadius: "8px",
                }}
              >
                <CloseOutlinedIcon
                  sx={{
                    width: "1.3rem",
                    height: "1.3rem",
                    cursor: "pointer",
                  }}
                  onClick={handleClose}
                />
              </Box>
            ) : (
              <DashboardChartModal
                chartProps={chartProps}
                tableProps={tableProps}
                isDataEmpty={isDataEmpty}
              />
            )}
          </Typography>
          <div
            className="chartContainer"
            style={{
              display: "flex",
              alignItems: "center",
              height: "30rem",
              ...style,
            }}
          >
            {isLoading ? (
              <Skeleton
                variant="rounded"
                height={500}
                width={"100%"}
                sx={{ marginTop: "1rem" }}
                data-testid="skeleton"
              />
            ) : (
              !isDataEmpty && (
                <ResponsiveBar
                  data={data}
                  keys={keys}
                  indexBy="name"
                  margin={{ top: 50, right: 120, bottom: 50, left: 60 }}
                  padding={dynamicPadding}
                  colors={colors}
                  borderColor={{
                    from: "color",
                    modifiers: [["darker", 1.6]],
                  }}
                  label={label}
                  axisLeft={{
                    format: valueFormat,
                  }}
                  labelSkipHeight={labelSkipHeight}
                  labelTextColor={{
                    from: "color",
                    modifiers: [["darker", 3]],
                  }}
                  legends={legends}
                  theme={{
                    legends: {
                      text: {
                        fontSize: 14,
                      },
                    },
                    labels: {
                      text: {
                        fontSize: 10,
                      },
                    },
                  }}
                  tooltip={tooltip}
                />
              )
            )}
            {!isLoading && isDataEmpty && (
              <Typography
                component="div"
                sx={{
                  textAlign: "center",
                  width: "100%",
                  backgroundColor: "background.paper",
                  color: "grey.500",
                  marginTop: "2rem",
                }}
              >
                {noDataTitle}
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
    </Box>
  );
};

export default DashboardBarChart;
