const columns = [
  "TITLE",
  "STATUS",
  "FIRST RESPONSE SLA",
  "SLA STATUS",
  "ISSUE CATEGORY",
  "DESCRIPTION",
  "CLICKUP LINK",
  "ASSIGNEE",
  "TEAM",
  "SUB TEAM",
  "PRIORITY",
  "CREATED ON",
  "DUE DATE",
  "COMPLETED AT",
];

const slaStatusMessage = {
  "On Track":
    "On track: we are actively working on this ticket and expected it to be resolved within the agreed SLA.",
  Breached:
    "Breached: we were unable to resolve this ticket in the agreed SLA, we are actively working on this ticket and expect to be able to provide a resolution soon.",
  "At Risk":
    "At risk: we may not be able to resolve this ticket in the agreed SLA. We are actively working on this ticket and expect to be able to provide a resolution soon.",
  Paused:
    "We are awaiting feedback from the issue raiser. If no concerns are raised, the ticket will be auto-closed soon.",
  Achieved: "Achieved",
};

const firstResponseMessage = {
  Achieved: "First response SLA: Achieved",
  Breached: "First response SLA: Breached",
};

export { columns, slaStatusMessage, firstResponseMessage };
