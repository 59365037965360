const StartEndTime = ({userShift}) => {
    if (
      Object.keys(userShift).length === 0 ||
      Object.keys(userShift.working_shift).length === 0
    )
      return <div>N/A</div>;
    else
      return Object.entries(userShift.working_shift).map((item, index) => {
        const [key, value] = item;
        const shiftKey = key === "start_time" ? "Start time" : "End time";
        const keyValuePair = `${shiftKey} : ${value}`;
        return <div key={index}>{keyValuePair}</div>;
      });
  };

  export default StartEndTime;