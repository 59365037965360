export default function LinearDeterminate({
  progressBarBackground,
  progressBarFill,
  progressValue,
}) {
  return (
    <div
      className="card_progress_bar"
      role="progress"
      style={{
        backgroundColor: `${progressBarBackground}`,
      }}
    >
      <div
        className="card_progress_view"
        data-testid="progress-view"
        style={{
          width: `${progressValue}%`,
          backgroundColor: `${progressBarFill}`,
        }}
      ></div>
    </div>
  );
}
