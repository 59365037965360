import { Card } from "../DashboardCards";

const FirstResponseSLA = ({
  isFirstResponseLoading,
  getLoader,
  firstResponseError,
  firstResponseCard,
  totalFirstResponseCount,
  parameterString,
  dateUserSelectedFilter,
}) => {
  return (
    <div className="first_response_sla">
      <div className="dashboard_cards_heading">First response SLA</div>
      {isFirstResponseLoading && getLoader()}
      {!firstResponseError && !isFirstResponseLoading && (
        <div className="dashboard_first_response_card">
          <Card
            key="first_response_sla_card"
            cardIndex={0}
            cardInfo={firstResponseCard}
            totalCount={totalFirstResponseCount}
            parameterString={parameterString}
            dateUserSelectedFilter={dateUserSelectedFilter}
            shouldClickBeDisabled={false}
            closedOrOpen="First response met"
          />
        </div>
      )}
    </div>
  );
};

export default FirstResponseSLA;
