import { useEffect, useRef, useState } from "react";
import {
  CustomItemTooltipContentStatus,
  CustomItemTooltipContentTeam,
  adjustDataForVisualization,
  calculateBarLabel,
  calculateMaxValue,
  filterDataByDuration,
  findLegendsToBeDisplayed,
  findSlaData,
  formatClosedData,
  formatSquadData,
  formatStatusData,
  getLevelRows,
  getLoader,
  getSquadRows,
  getStatusRows,
  isClosedDataEmpty,
  isDataEmpty,
  updateFirstResponseParam,
} from "../../utils/DashboardFunctions";
import ClosedTickets from "../ClosedCardsComponent";
import DashboardBarChart from "../DashboardBarChart";
import FirstResponseSLA from "../FirstResponseSLAComponent";
import {
  colors,
  colorsSquad,
  colorsStatus,
  keys,
  keysSquad,
  keysStatus,
  legends,
  valueFormatter,
  valueFormatterStatus,
} from "../DashboardBarChart/series";

import { generateDateParamString } from "../../utils/clearAllSelectedFilters";
import DateFilters from "../DateFilters";
import {
  getClosedBarChartData,
  getClosedTickets,
  getDashboardMetricData,
  getDashboardMetricExportData,
  getFirstResponseSLA,
  getSquadBarChartData,
  getStatusBarChartData,
} from "../../services/apiServices";
import { generateDashboardDateParamString } from "../../utils/clearAllSelectedFiltersForDashboard";
import InformationCircle from "../../assets/information-circle-2.svg";
import "./style.css";
import { Tooltip } from "@mui/material";
import { REACT_APP_API_PATH as baseURL, CLICKUP_URL } from "../../config";
import {
  levelHeadCells,
  levelTableKeys,
  squadHeadCells,
  squadTableKeys,
  statusHeadCells,
  statusTableKeys,
} from "../../utils/DashboardModalConstants";

const DashboardDateFiltersRows = ({
  parameterString,
  isUserFilterSet,
  userSelectedFilter,
}) => {
  const [closedTicketsCards, setClosedTicketsCards] = useState([]);
  const [firstResponseCard, setFirstResponseCard] = useState({});

  const [totalClosedTicketsCount, setTotalClosedTicketsCount] = useState(0);
  const [totalFirstResponseCount, setTotalFirstResponseCount] = useState(0);

  const [isLoading, setIsLoading] = useState(true);
  const [isClosedTicketsLoading, setIsClosedTicketsLoading] = useState(true);
  const [isFirstResponseLoading, setIsFirstResponseLoading] = useState(true);

  const [exportIsLoading, setExportIsLoading] = useState(false);
  const [metricIsLoading, setMetricIsLoading] = useState(false);

  const [url, setUrl] = useState(`${baseURL}v1/dashboard_metric/?limit=100`);
  const [statusRows, setStatusRows] = useState([]);
  const [levelRows, setLevelRows] = useState([]);
  const [squadRows, setSquadRows] = useState([]);
  const [countTickets, setCountTickets] = useState();

  const [closedTicketsError, setClosedTicketsError] = useState(null);
  const [firstResponseError, setFirstResponseError] = useState(null);

  const [statusBarData, setStatusBarData] = useState([]);
  const [squadBarData, setSquadBarData] = useState([]);
  const [closedBarData, setClosedBarData] = useState([]);

  const [dateUserSelectedFilter, setDateUserSelectedFilter] = useState({
    From: "",
    To: "",
  });

  const prevFetchUrl = useRef(null);

  const getParamStringForClosedTickets = (parameterString) => {
    return `${parameterString.replace("&", "?")}`;
  };

  const fetchClosedTicketsData = async () => {
    setIsClosedTicketsLoading(true);
    const customDateParamString = generateDashboardDateParamString(
      dateUserSelectedFilter,
      true
    );

    const paramStringForClosedTickets = `${parameterString}${customDateParamString}`;

    const response = await getClosedTickets(
      paramStringForClosedTickets,
      baseURL,
      setIsClosedTicketsLoading
    );
    setClosedTicketsCards(findSlaData(response.data));
    setTotalClosedTicketsCount(response.total_count);
    setClosedTicketsError(null);
    setIsClosedTicketsLoading(false);
  };

  const fetchDataForFirstResponse = async () => {
    setIsFirstResponseLoading(true);

    const customDateParamString = generateDateParamString(
      parameterString,
      dateUserSelectedFilter
    );

    const paramStringForFirstResponse = getParamStringForClosedTickets(
      customDateParamString
    );

    const response = await getFirstResponseSLA(
      paramStringForFirstResponse,
      baseURL
    );
    setFirstResponseCard({
      name: "First response SLA",
      count: response.achieved_count,
      percent: response.percent,
    });
    setTotalFirstResponseCount(response.total_count);
    setFirstResponseError(null);
    setIsFirstResponseLoading(false);
  };

  const fetchSquadBarChartData = async () => {
    setIsLoading(true);

    const customDateParamCreatedDate = generateDashboardDateParamString(
      dateUserSelectedFilter,
      false
    );

    const customDateParamString = `${parameterString}${customDateParamCreatedDate}`;

    const response = await getSquadBarChartData(customDateParamString, baseURL);
    setSquadBarData(response);
    setIsLoading(false);
  };

  const filteredSquadBarData = filterDataByDuration(squadBarData);

  const maxValueSquad = calculateMaxValue(filteredSquadBarData);

  const adjustedSquadBarData = adjustDataForVisualization(
    filteredSquadBarData,
    maxValueSquad
  );

  const fetchClosedBarChartData = async () => {
    setIsLoading(true);

    const customDateParamString = generateDashboardDateParamString(
      dateUserSelectedFilter,
      true
    );
    const paramStringForClosedTickets = `${parameterString}${customDateParamString}`;

    const response = await getClosedBarChartData(
      paramStringForClosedTickets,
      baseURL
    );

    setClosedBarData(response);
    setIsLoading(false);
  };

  const filteredStatusBarData = filterDataByDuration(statusBarData);

  const maxValueStatus = calculateMaxValue(filteredStatusBarData);
  const adjustedStatusBarData = adjustDataForVisualization(
    filteredStatusBarData,
    maxValueStatus
  );

  const fetchStatusBarChartData = async () => {
    setIsLoading(true);

    const customDateParamCreatedDate = generateDashboardDateParamString(
      dateUserSelectedFilter,
      false
    );

    const customDateParamString = `${parameterString}${customDateParamCreatedDate}`;

    const paramStringForClosedTickets = customDateParamString;

    const response = await getStatusBarChartData(
      paramStringForClosedTickets,
      baseURL
    );

    setStatusBarData(response);
    setIsLoading(false);
  };

  const fetchDashboardMetricData = async (customUrl) => {
    const fetchUrl = customUrl || url;

    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    setMetricIsLoading(true);

    const response = await getDashboardMetricData(fetchUrl);
    const ticketsCount = response.count;

    if (customUrl) {
      setStatusRows(getStatusRows(response.results));
      setLevelRows(getLevelRows(response.results));
      setSquadRows(getSquadRows(response.results));
    } else {
      setStatusRows([...statusRows, ...getStatusRows(response.results)]);
      setLevelRows([...levelRows, ...getLevelRows(response.results)]);
      setSquadRows([...squadRows, ...getSquadRows(response.results)]);
    }

    setCountTickets(ticketsCount);

    setUrl(response.next);

    setMetricIsLoading(false);
  };

  const handleExport = async (params) => {
    setExportIsLoading(true);
    const response = await getDashboardMetricExportData(params, baseURL);

    const csvText = await response.text();
    const trimmedCsvText = csvText.trim();

    const rows = trimmedCsvText.split("\n");

    const modifiedRows = rows.map((row, index) => {
      if (index === 0) {
        return row;
      }
      const columns = row.split(",");
      const existingId = columns[0];
      const link = `${CLICKUP_URL}${existingId}`;
      columns[0] = link;
      return columns.join(",");
    });

    const modifiedCsvText = modifiedRows.join("\n");

    const blob = new Blob([modifiedCsvText], { type: "text/csv" });

    const exportsResponseUrl = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = exportsResponseUrl;
    const currentTime = new Date().toISOString().slice(0, 19).replace(/T/, "-");
    const filename = `Guardian-Dashboard-Metric-Export-${currentTime}.csv`;
    link.setAttribute("download", filename);

    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
    setExportIsLoading(false);
  };

  useEffect(() => {
    if (isUserFilterSet) {
      fetchClosedTicketsData();
      fetchDataForFirstResponse();
      fetchStatusBarChartData();
      fetchSquadBarChartData();
      fetchClosedBarChartData();
    }
  }, [dateUserSelectedFilter, parameterString, isUserFilterSet]);

  return (
    <div>
      <div className="dashboard_date_filter_container">
        <div className="filter_by_date">Filter by date</div>
        <div className="date_filter_i_icon">
          <Tooltip
            title="Date filters aren't applied to Open tickets SLA Statuses"
            placement="bottom"
            arrow
            sx={{
              "& .MuiTooltip-tooltip": {
                marginTop: "0px !important",
                borderRadius: "0.1875rem",
                background: "var(--Base-Blue-grey-10, #3C3E49)",
              },
            }}
          >
            <img
              src={InformationCircle}
              alt="information_cirle"
              data-testid="information_tooltip_circle"
              style={{ width: "1rem", height: "1rem" }}
            />
          </Tooltip>
        </div>
        <div className="date-filters-dropdown">
          <DateFilters
            setDateUserSelectedFilter={setDateUserSelectedFilter}
            dateUserSelectedFilter={dateUserSelectedFilter}
            userSelectedFilter={userSelectedFilter}
          />
        </div>
      </div>
      <div className="dashboard_cards_container dashboard_closed_first_response_sectiion">
        <FirstResponseSLA
          isFirstResponseLoading={isFirstResponseLoading}
          getLoader={getLoader}
          firstResponseError={firstResponseError}
          firstResponseCard={firstResponseCard}
          totalFirstResponseCount={totalFirstResponseCount}
          parameterString={parameterString}
          dateUserSelectedFilter={dateUserSelectedFilter}
        />
        <ClosedTickets
          isClosedTicketsLoading={isClosedTicketsLoading}
          getLoader={getLoader}
          closedTicketsError={closedTicketsError}
          closedTicketsCards={closedTicketsCards}
          totalClosedTicketsCount={totalClosedTicketsCount}
          parameterString={parameterString}
          dateUserSelectedFilter={dateUserSelectedFilter}
        />
      </div>

      <div className="barChartDisplay">
        <DashboardBarChart
          sx={{ width: "60%", borderRadius: "8px" }}
          title="Average time spent in each status"
          metricIsLoading={metricIsLoading}
          countTickets={countTickets}
          parameters={parameterString.concat(
            generateDashboardDateParamString(dateUserSelectedFilter, false)
          )}
          fetchData={fetchDashboardMetricData}
          tableData={statusRows}
          tableHeadCells={statusHeadCells}
          tableKeys={statusTableKeys}
          isLoading={isLoading}
          isDataEmpty={isDataEmpty(statusBarData, "duration")}
          data={formatStatusData(adjustedStatusBarData)}
          keys={keysStatus}
          colors={colorsStatus}
          label={(item) => calculateBarLabel(item, maxValueStatus)}
          valueFormat={valueFormatterStatus}
          tooltip={(input) => CustomItemTooltipContentStatus(input)}
          noDataTitle="No data to display for Average time spent in each status"
          handleExport={() =>
            handleExport(
              parameterString.concat(
                generateDashboardDateParamString(dateUserSelectedFilter, false)
              )
            )
          }
          exportIsLoading={exportIsLoading}
        />
        <DashboardBarChart
          sx={{ width: "38%", borderRadius: "8px", marginLeft: "2rem" }}
          title="Average time spent in each level"
          metricIsLoading={metricIsLoading}
          countTickets={countTickets}
          parameters={parameterString.concat(
            generateDashboardDateParamString(dateUserSelectedFilter, false)
          )}
          fetchData={fetchDashboardMetricData}
          tableData={levelRows}
          tableHeadCells={levelHeadCells}
          tableKeys={levelTableKeys}
          isLoading={isLoading}
          isDataEmpty={isDataEmpty(squadBarData, "duration")}
          data={formatSquadData(adjustedSquadBarData)}
          keys={keysSquad}
          colors={colorsSquad}
          label={(item) => calculateBarLabel(item, maxValueSquad)}
          valueFormat={valueFormatterStatus}
          tooltip={(input) => CustomItemTooltipContentStatus(input)}
          noDataTitle="No data to display for Average time spent in each level"
          handleExport={() =>
            handleExport(
              parameterString.concat(
                generateDashboardDateParamString(dateUserSelectedFilter, false)
              )
            )
          }
          exportIsLoading={exportIsLoading}
        />
      </div>

      <div className="barChart-for-closed-by-squad">
        <DashboardBarChart
          title="Tickets closed by squad"
          metricIsLoading={metricIsLoading}
          countTickets={countTickets}
          parameters={parameterString.concat(
            generateDashboardDateParamString(dateUserSelectedFilter, true)
          )}
          fetchData={fetchDashboardMetricData}
          tableData={squadRows}
          tableHeadCells={squadHeadCells}
          tableKeys={squadTableKeys}
          isLoading={isLoading}
          isDataEmpty={isClosedDataEmpty(closedBarData)}
          data={formatClosedData(closedBarData)}
          keys={keys}
          colors={colors}
          label={(item) => {
            if (item.value === 0) {
              return null;
            }

            return `${item.value}%`;
          }}
          valueFormat={valueFormatter}
          labelSkipHeight={8}
          legends={findLegendsToBeDisplayed(
            legends,
            formatClosedData(closedBarData)
          )}
          tooltip={(input) =>
            CustomItemTooltipContentTeam(input, closedBarData)
          }
          noDataTitle="No data to display for Tickets closed by squad"
          handleExport={() =>
            handleExport(
              parameterString.concat(
                generateDashboardDateParamString(dateUserSelectedFilter, true)
              )
            )
          }
          exportIsLoading={exportIsLoading}
        />
      </div>
    </div>
  );
};

export default DashboardDateFiltersRows;
