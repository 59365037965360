export const defaultFiltersForTicketSummary = {
  assignees: [],
  status: [],
  teams: [],
  sub_teams: [],
  date: {
    ["Date created"]: {
      From: "",
      To: "",
    },
    ["Date due"]: {
      From: "",
      To: "",
    },
  },
  closedDate: {
    From: "",
    To: "",
  },
  sla_status: [],
  first_response_sla: [],
  search: ""
};
