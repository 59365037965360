import React from "react";
import ClockLoader from "react-spinners/ClockLoader";
import "./style.css";

function ComingSoonComponent() {
  return (
    <div className="comingSoon">
      <ClockLoader color="#36d7b7" size={100} />{" "}
      <span className="spanStyle">Coming Soon</span>
    </div>
  );
}

function ComingSoon() {
  return <ComingSoonComponent />;
}

export default ComingSoon;
