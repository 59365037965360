import { createHeadCellObject } from "../../utils/common_functions";

export const headCells = [
    createHeadCellObject("username", false, true, "Username"),
    createHeadCellObject("squadname", true, false, "Squad Name"),
    createHeadCellObject("relatedteams", true, false, "Teams"),
    createHeadCellObject("relatedsubteams", true, false, "Sub Teams"),
    createHeadCellObject("availability", true, false, "Availability"),
    createHeadCellObject("workingdays", true, false, "Working Days"),
    createHeadCellObject("workingtime", true, false, "Working Time"),
    createHeadCellObject("role", true, false, "Role"),
    createHeadCellObject("actions", true, false, "Actions"),
  ];