import CompletedSlaStatus from "../../assets/Completed.svg";
import PausedSlaStatus from "../../assets/paused.svg";
import OnTrackSlaStatus from "../../assets/OnTrack.svg";
import AtRiskSlaStatus from "../../assets/AtRisk.svg";
import BreachedSlaStatus from "../../assets/BreachedSlaStatus.svg";

import "./styles.css";
import { Tooltip } from "@mui/material";

export const SlaStatus = ({ slaStatusType, tooltipText }) => {
  let slaStatusName = null;
  let slaStatusBackground = null;

  if (slaStatusType === "Achieved") {
    slaStatusName = CompletedSlaStatus;
    slaStatusBackground = "var(--Base-Green-5, #00C853)";
  } else if (slaStatusType === "Breached") {
    slaStatusName = BreachedSlaStatus;
    slaStatusBackground = "var(--Base-Red-4, #FF5546)";
  } else if (slaStatusType === "At Risk") {
    slaStatusName = AtRiskSlaStatus;
    slaStatusBackground = "var(--Base-Amber-5, #FFAB00)";
  } else if (slaStatusType === "On Track") {
    slaStatusName = OnTrackSlaStatus;
    slaStatusBackground = "var(--Base-Green-5, #00C853)";
  } else if (slaStatusType === "Paused") {
    slaStatusName = PausedSlaStatus;
    slaStatusBackground = "var(--Base-Blue-grey-9, #676B7E)";
  }

  return (
    <div
      className="sla_breached"
      style={{
        display: "flex",
        width: "3em",
        height: "4em",
        padding: "0.9375em 0em",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "1em",
        flexShrink: "0",
      }}
    >
      <Tooltip
        title={tooltipText}
        placement="right-end"
        arrow
        sx={{
          "& .MuiTooltip-tooltip": {
            marginTop: "0px !important",
          },
        }}
      >
        <div
          className="badge"
          style={{
            display: "flex",
            padding: "0.25em 0.5em",
            alignItems: "center",
            gap: "0.5em",
            borderRadius: "1.2em",
            background: `${slaStatusBackground}`,
          }}
        >
          <div
            className="icon"
            style={{
              display: "flex",
              width: "1.65em",
              height: "1.65em",
              padding: "0.1875em 0.1875em 0.171875em 0.25em",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src={slaStatusName} loading="lazy" alt="slaStatus" />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};
