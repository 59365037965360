import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import _without from "lodash/without";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import "./style.css";
import { Loader } from "../Loader";
import CustomTimePicker from "../CustomTimePicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { tooltip_message } from "../../utils/constants";
import {
  get_available_support_levels,
  extractIds,
  parseTime,
  getTitle,
  get_list,
} from "./component_functions";
import {
  updatedObject,
  arrayToUpperCase,
  arrayToLowerCase,
} from "../../utils/common_functions";
import { SubTeamTable } from "../SubTeamEditableTable";
import { Unstable_NumberInput as NumberInput } from "@mui/base/Unstable_NumberInput";
import { errorNotify } from "../../components/ToastComponent";
import Autocomplete from "@mui/material/Autocomplete";
import { ClickUpList } from "../ClickUpList";
import { SaveCancel } from "../SaveCancel";

dayjs.extend(utc);
dayjs.extend(timezone);

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: "11rem",
      minWidth: "10vw",
      padding: "0 0 0 0",
    },
    sx: {
      "& .MuiTypography-root": {
        fontSize: "14px",
      },
    },
  },
};
const notAllowedLiterals = ["", undefined, null, " ", "undefined"];

const BasicDetailsCustomisedChips = ({
  customField,
  handleDelete,
  edit_permission,
}) => {
  return customField !== undefined ? (
    <Stack direction="row" spacing={1}>
      {customField.slice(0, 3).map((field, key) => (
        <Chip
          key={field}
          label={field}
          variant="outlined"
          onDelete={(e) => handleDelete(e, field)}
          sx={{
            backgroundColor: "#F3EBFF",
            border: "none",
            color: "#3C3E49",
            fontSize: "12px",
            "& .hover": {
              backgroundColor: "#F3EBFF",
            },
          }}
          disabled={!edit_permission()}
          deleteIcon={
            <CloseIcon
              sx={{
                color: "#3C3E49 !important",
                fontSize: "15px !important",
              }}
            />
          }
        />
      ))}
      <Tooltip title={customField.slice(3, customField.length).join(",")}>
        {customField.length - 3 > 0 ? (
          <Chip
            key={"+" + (customField.length - 3)}
            label={"+" + (customField.length - 3)}
            variant="outlined"
            sx={{
              backgroundColor: "#F3EBFF",
              border: "none",
              color: "#3C3E49",
              fontSize: "12px",
            }}
          />
        ) : (
          <></>
        )}
      </Tooltip>
    </Stack>
  ) : (
    <></>
  );
};

const AutoCloseSection = ({
  autoCloseTask,
  autoCloseHours,
  access_type,
  setDataForPutApi,
  setAutoCloseHours,
  dataForPutApi,
}) => {
  return autoCloseTask ? (
    <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
      <Grid xs={10} item sx={{ lineHeight: "5rem" }}>
        Auto close hours
      </Grid>
      <Grid xs={2} item sx={{ paddingTop: "1rem", textAlign: "end" }}>
        <NumberInput
          disabled={access_type == "view"}
          value={autoCloseHours}
          onChange={(e, v) => {
            if (v > 8765)
              errorNotify("Auto close hours can't be more than 8766");
            setAutoCloseHours(v);
            setDataForPutApi({
              ...dataForPutApi,
              auto_close_hours: v,
            });
          }}
          max={8766}
          min={0}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const KnowledgeBasenameSection = ({
  natashaIntegration,
  edit_permission,
  knowledgeBasename,
  setKnowledgeBasename,
  setDataForPutApi,
  dataForPutApi,
}) => {
  return natashaIntegration ? (
    <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
      <Grid xs={10} item sx={{ lineHeight: "5rem" }}>
        Knowledge base name
      </Grid>
      <Grid xs={2} item sx={{ paddingTop: "1rem", textAlign: "end" }}>
        <TextField
          id="outlined-basic"
          variant="outlined"
          disabled={!edit_permission}
          sx={{
            "& .MuiOutlinedInput-root": {
              maxHeight: "36px !important",
              fontSize: "14px",
              color: "#3c3e49",
            },
          }}
          value={knowledgeBasename}
          onChange={(e) => {
            setKnowledgeBasename(e.target.value);
            setDataForPutApi({
              ...dataForPutApi,
              knowledge_base_name: e.target.value,
            });
          }}
        />
      </Grid>
    </Grid>
  ) : (
    <></>
  );
};

const SubTeamLevelChip = ({
  enforceSubTeam,
  handleDeleteSubTeamSupportLevels,
  edit_permission,
}) => {
  return (
    <Grid justifySelf="flex-end" sx={{ height: "2rem" }}>
      {" "}
      <Stack direction="row" spacing={1}>
        {enforceSubTeam.map((level) => (
          <Chip
            key={level}
            label={level}
            sx={{
              backgroundColor: "#F3EBFF",
              border: "none",
              fontSize: "12px",
            }}
            onDelete={(e) => {
              handleDeleteSubTeamSupportLevels(e, level);
            }}
            disabled={!edit_permission}
            deleteIcon={
              <CloseIcon
                sx={{
                  color: "#3C3E49 !important",
                  fontSize: "15px !important",
                }}
              />
            }
            variant="outlined"
          />
        ))}
      </Stack>
    </Grid>
  );
};

const SubTeamTableAndText = ({
  enableSubTeam,
  edit_permission,
  setSubTeamcustomField,
  setDataForPutApi,
  subTeamcustomField,
  dataForPutApi,
  subteamsList,
  handleUpdateOrCreateSubTeam,
  page,
  deactivateSubTeam,
  customFieldDropdownValues,
  isLoading,

  access_type,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  customID,
  setClickupList,
}) => {
  const [dataFromTable, setDataFromTable] = useState([]);

  useEffect(() => {
    setDataFromTable(subteamsList);
  }, [subteamsList?.length]);

  const disabledParentDropdown = (value) => {
    setDataFromTable(value);
  };
  return (
    enableSubTeam && (
      <>
        {/* sub team custom field id */}
        <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
          <Grid xs={10} item sx={{ lineHeight: "5rem" }}>
            Sub team custom field
            <Tooltip title={tooltip_message.sub_team_custom_field}>
              <InfoOutlinedIcon
                sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
              />
            </Tooltip>
          </Grid>
          <Grid
            xs={2}
            item
            sx={{
              alignContent: "center",
            }}
          >
            <Autocomplete
              className="subTeamInputBox"
              disablePortal={true}
              disableClearable
              id="combo-box-demo"
              options={customFieldDropdownValues}
              getOptionLabel={(options) => options.name}
              disabled={dataFromTable.length > 0}
              value={subTeamcustomField}
              sx={{
                width: "100%",
                "& .MuiAutocomplete-input, & .MuiInputLabel-root": {
                  fontSize: "14px",
                },

                "& .MuiAutocomplete-popper": {
                  backgroundColor: "red",
                },
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
              renderOption={(props, option) => (
                <MenuItem
                  key={option.id}
                  value={option.id}
                  sx={{
                    padding: "5px 5px",
                    whiteSpace: "unset",
                    wordBreak: "break-all",
                  }}
                  onClick={() => {
                    setSubTeamcustomField(option);
                    setDataForPutApi({
                      ...dataForPutApi,
                      sub_team_custom_field: option.id,
                    });
                  }}
                >
                  <Radio
                    size="small"
                    checked={option.name == subTeamcustomField.name}
                    sx={{
                      color: "#C0C3CE",
                      "&.Mui-checked": {
                        color: "#7C4DFF",
                      },
                    }}
                  />
                  <ListItemText
                    disableTypography
                    primary={option.name}
                    sx={{ fontSize: "13px", color: "#3C3E49" }}
                  />
                </MenuItem>
              )}
            />
          </Grid>
        </Grid>{" "}
        {/*sub team list table */}
        <Grid container className="subTeamTableGrid">
          {" "}
          {subTeamcustomField.id != "" && (
            <>
              <Grid className="sectionHeading">Sub team list</Grid>
              <Grid sx={{ paddingTop: "1rem" }}>
                {" "}
                <SubTeamTable
                  subTeams={subteamsList}
                  updateOrCreateSubTeam={handleUpdateOrCreateSubTeam}
                  page={page}
                  deactivateSubTeam={deactivateSubTeam}
                  isLoading={isLoading}
                  subTeamcustomField={subTeamcustomField}
                  edit_permission={edit_permission}
                  disabledParentDropdown={disabledParentDropdown}
                  dataForPutApi={dataForPutApi}
                  access_type={access_type}
                  spaces={spaces}
                  folders={folders}
                  lists={lists}
                  listsWithoutFolder={listsWithoutFolder}
                  fetchSpaceData={fetchSpaceData}
                  fetchFolderData={fetchFolderData}
                  fetchListData={fetchListData}
                  fetchListWithoutFolderData={fetchListWithoutFolderData}
                  handleItemClick={handleItemClick}
                  openItems={openItems}
                  customID={customID}
                  setClickupList={setClickupList}
                />
              </Grid>{" "}
            </>
          )}
        </Grid>
      </>
    )
  );
};

const HandleSubTeamcustomField = (
  sub_team_custom_field,
  customFieldDropdownValues
) => {
  return notAllowedLiterals.includes(sub_team_custom_field)
    ? { id: "", name: "", type: "", type_config: {} }
    : customFieldDropdownValues.filter(
        (item) => String(item.id) === sub_team_custom_field
      )[0];
};

const convertToLower = (availableLevels) =>
  availableLevels.length ? availableLevels[0].toLowerCase() : "";

const get_enabled_support_levels = (page, field) => {
  if (page == "edit") return field;
  return ["L1", "L2", "L3"];
};
const EditTeamPage = ({
  page,
  formData,
  access_type,
  custom_field_dropdown,
  isLoading,
  setIsLoading,
  getDataForPutApi,
  getDataForEnableAutomation,
  deactivateSubTeam,
  handleUpdateOrCreateSubTeam,
  customFieldDropdownValues,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
}) => {
  const edit_permission = () => {
    return ["edit", "add"].includes(access_type);
  };
  const [teamName, setTeamName] = useState("");
  const [customField, setCustomField] = useState([]);
  const [customFieldID, setCustomFieldID] = useState([]);
  const [customID, setCustomID] = useState();
  const [enableAutomation, setEnableAutomation] = useState(false);
  const [autoCloseTask, setAutoCloseTask] = useState(false);
  const [autoCloseHours, setAutoCloseHours] = useState(0);
  const [natashaIntegration, setNatashaIntegration] = useState(false);
  const [knowledgeBasename, setKnowledgeBasename] = useState("");
  const [supportLevels, setSupportLevels] = useState([]);
  const [enabledSupportLevels, setEnabledSupportLevels] = useState([]);
  const [autoEscalation, setAutoEscalation] = useState(false);
  const [sendExpertEmail, setSendExpertEmail] = useState(false);
  const [slackChanelForQueries, setSlackChanelForQueries] = useState("");
  const [dailySummaryChannel, setDailySummaryChannel] = useState("");
  const [notificationFrequency, setNotificationFrequency] = useState([]);
  const [escalationNotes, setEscalationNotes] = useState(false);
  const [subTeamcustomField, setSubTeamcustomField] = useState({
    id: "",
    name: " ",
    type: "",
    type_config: "",
  });
  const [enableResolutionNotes, setEnableResolutionNotes] = useState(false);
  const [enableReviewStatus, setEnableReviewStatus] = useState(false);
  const [enforceSubTeam, setEnforceSubTeam] = useState([]);
  const [enableFirstResponseNotification, setEnableFirstResponseNotification] =
    useState(false);
  const [subteamsList, setSubteamsList] = useState([]);

  const [dataForPutApi, setDataForPutApi] = useState({
    name: teamName,
    clickup_list_id: customID,
    mandatory_custom_fields: [],
    mandatory_custom_field_ids: [],
    sub_teams: [],
  });
  const [saveButtonStatus, setSaveButtonStatus] = useState(false);

  useEffect(() => {
    if (formData.length != 0) {
      const updatedObjectRows = updatedObject(formData);

      setCustomField(
        get_list(updatedObjectRows.mandatory_custom_fields, "code")
      );
      setCustomFieldID(
        get_list(updatedObjectRows.mandatory_custom_fields, "id")
      );
      setTeamName(updatedObjectRows.name);
      setDataForPutApi({
        name: updatedObjectRows.name,
        clickup_list_id: updatedObjectRows.clickup_list_id,
        mandatory_custom_fields: customField,
        mandatory_custom_field_ids: customFieldID,
        sub_teams: updatedObjectRows.sub_teams,
      });

      setCustomID(updatedObjectRows.clickup_list_id);

      setEnabledSupportLevels(
        get_enabled_support_levels(
          page,
          updatedObjectRows.enabled_support_levels
        )
      );
      setEnableAutomation(updatedObjectRows.is_automation_enabled);
      setAutoCloseTask(updatedObjectRows.auto_close_tasks);
      setAutoCloseHours(updatedObjectRows.auto_close_hours);
      setKnowledgeBasename(updatedObjectRows.knowledge_base_name);
      setSupportLevels(
        get_available_support_levels(
          updatedObjectRows.has_l1_support_level,
          updatedObjectRows.has_l2_support_level,
          updatedObjectRows.has_l3_support_level
        )
      );
      setNatashaIntegration(updatedObjectRows.enable_natasha_integration);
      setAutoEscalation(updatedObjectRows.auto_escalation);
      setSendExpertEmail(updatedObjectRows.send_expert_guide_email);
      setSlackChanelForQueries(updatedObjectRows.slack_channel_for_queries);

      setDailySummaryChannel(updatedObjectRows.daily_summary_slack_channel_id);
      setNotificationFrequency(
        notAllowedLiterals.includes(updatedObjectRows.notification_frequency)
          ? []
          : String(updatedObjectRows.notification_frequency).split(" ")
      );

      setEscalationNotes(updatedObjectRows.escalation_notes_required);

      setEnforceSubTeam(
        arrayToUpperCase(updatedObjectRows.enforce_sub_team_for_level)
      );
      setEnableResolutionNotes(formData.resolution_notes_required);
      setEnableReviewStatus(formData.enable_review_status);
      setEnableFirstResponseNotification(
        updatedObjectRows.enable_first_response_notification
      );
      setSubteamsList(formData.sub_teams);
    }
  }, [formData?.length, isLoading]);

  useEffect(() => {
    setSubTeamcustomField(
      HandleSubTeamcustomField(
        formData.sub_team_custom_field,
        customFieldDropdownValues
      )
    );
  }, [customFieldDropdownValues, isLoading]);

  const handleGoBack = () => {
    window.location.href = `/teams`;
  };

  const handleDelete = (e, value) => {
    e.preventDefault();
    setCustomField((current) => _without(current, value));
    setDataForPutApi({
      ...dataForPutApi,
      mandatory_custom_field_ids: extractIds(
        _without(customField, value),
        custom_field_dropdown
      ),
    });
    setSaveButtonStatus(true);
  };

  const handleDeleteNotificationFreq = (e, value) => {
    e.preventDefault();
    setNotificationFrequency((current) => _without(current, value));
    const payload_value = _without([...notificationFrequency], value);
    setDataForPutApi({
      ...dataForPutApi,
      notification_frequency: payload_value.join(" "),
    });
    setSaveButtonStatus(true);
  };

  const handleChangeSupportLevels = (event) => {
    const {
      target: { value },
    } = event;
    setSupportLevels(typeof value === "string" ? value.split(",") : value);
    // Update dataForPutApi with individual flags
    setDataForPutApi({
      ...dataForPutApi,
      has_l1_support_level: value.includes("L1"),
      has_l2_support_level: value.includes("L2"),
      has_l3_support_level: value.includes("L3"),
    });
    setSaveButtonStatus(true);
  };

  const handleDeleteSupportLevels = (e, value) => {
    e.preventDefault();
    setSupportLevels((current) => _without(current, value));
    const available_levels = _without(supportLevels, value);
    setDataForPutApi({
      ...dataForPutApi,
      has_l1_support_level: available_levels.includes("L1"),
      has_l2_support_level: available_levels.includes("L2"),
      has_l3_support_level: available_levels.includes("L3"),
    });
    setSaveButtonStatus(true);
  };

  const handleSetClickupListID = (value) => {
    setDataForPutApi({
      ...dataForPutApi,
      clickup_list_id: value,
    });
    setSaveButtonStatus(true);
  };

  const handleEnableAutomation = () => {
    setEnableAutomation(!enableAutomation);
    setSaveButtonStatus(true);
  };

  const handleAutoClose = () => {
    setAutoCloseTask(!autoCloseTask);
    setDataForPutApi({ ...dataForPutApi, auto_close_tasks: !autoCloseTask });
    setSaveButtonStatus(true);
  };

  const handleNatashaIntegration = () => {
    setNatashaIntegration(!natashaIntegration);
    setDataForPutApi({
      ...dataForPutApi,
      enable_natasha_integration: !natashaIntegration,
    });
    setSaveButtonStatus(true);
  };

  const handleAutoEscalation = () => {
    setAutoEscalation(!autoEscalation);
    setDataForPutApi({
      ...dataForPutApi,
      auto_escalation: !autoEscalation,
    });
    setSaveButtonStatus(true);
  };

  const handleEscalationNotes = () => {
    setEscalationNotes(!escalationNotes);
    setDataForPutApi({
      ...dataForPutApi,
      escalation_notes_required: !escalationNotes,
    });
    setSaveButtonStatus(true);
  };

  const handleEnableResolutionNotes = () => {
    setEnableResolutionNotes(!enableResolutionNotes);
    setDataForPutApi({
      ...dataForPutApi,
      resolution_notes_required: !enableResolutionNotes,
    });
    setSaveButtonStatus(true);
  };

  const handleEnableReviewStatus = () => {
    setEnableReviewStatus(!enableReviewStatus);
    setDataForPutApi({
      ...dataForPutApi,
      enable_review_status: !enableReviewStatus,
    });
    setSaveButtonStatus(true);
  };

  const handleSendExpertEmail = () => {
    setSendExpertEmail(!sendExpertEmail);
    setDataForPutApi({
      ...dataForPutApi,
      send_expert_guide_email: !sendExpertEmail,
    });
    setSaveButtonStatus(true);
  };

  const handleEnableFirstResponseNotification = () => {
    setEnableFirstResponseNotification(!enableFirstResponseNotification);
    setDataForPutApi({
      ...dataForPutApi,
      enable_first_response_notification: !enableFirstResponseNotification,
    });
    setSaveButtonStatus(true);
  };

  const handleChangeSubTeamLevels = (event) => {
    const {
      target: { value },
    } = event;
    setEnforceSubTeam(value);
    setDataForPutApi({
      ...dataForPutApi,
      enforce_sub_team_for_level: arrayToLowerCase(value),
    });
    setSaveButtonStatus(true);
  };

  const handleDeleteSubTeamSupportLevels = (event, value) => {
    event.preventDefault();
    setEnforceSubTeam((current) => _without(current, value));
    const availableLevels = _without(enforceSubTeam, value);
    setDataForPutApi({
      ...dataForPutApi,
      enforce_sub_team_for_level: arrayToLowerCase(availableLevels),
    });
    setSaveButtonStatus(true);
  };

  const onAcceptTimePicker = (event) => {
    // parseTime(timeValue)

    const notif_list = [
      ...new Set([...notificationFrequency, parseTime(event)]),
    ];
    setNotificationFrequency(notif_list);
    setDataForPutApi({
      ...dataForPutApi,
      notification_frequency: notif_list.join(" "),
    });
  };

  // send data to parent on click of save button
  const sendDataForPatch = () => {
    getDataForPutApi(dataForPutApi);
    if (access_type == "edit" && !formData.is_automation_enabled) {
      getDataForEnableAutomation();
    }
    setSaveButtonStatus(false);
  };

  const hideEnableAutomation = location.pathname.includes("add-team");

  return Object.keys(formData)?.length == 0 ? (
    <div className="loaderDiv">
      <Loader
        loading={Object.keys(formData).length == 0}
        color={"#8f5ff7"}
        size={20}
      />
    </div>
  ) : (
    <Grid
      sx={{
        paddingTop: "2rem",
        backgroundColor: "white",
      }}
    >
      {/* back arrow and heading */}
      <Grid className="EditTeamHeading">
        <Button
          onClick={handleGoBack}
          sx={{
            "&:hover": {
              backgroundColor: "transparent",
            },
          }}
        >
          <ArrowBackIcon fontSize="small" />
        </Button>
        {getTitle(access_type, page, teamName)}
      </Grid>

      {/* main form section */}
      <Grid className="mainGrid">
        {/* basic details section */}
        <Grid className="basicDetails">
          <Grid
            sx={{
              color: "#3C3E49",
              fontSize: "14px",
              padding: "1rem 0rem",
              alignItems: "center",
            }}
            container
          >
            <Grid xs={9} item sx={{ lineHeight: "3rem" }}>
              <h3 className="sectionHeading" style={{ margin: 0 }}>
                Basic Details
              </h3>{" "}
            </Grid>
            {!enableAutomation && !hideEnableAutomation && (
              <Grid
                xs={3}
                item
                sx={{
                  textAlign: "end",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <span>Enable Automation</span>
                <Switch
                  checked={enableAutomation}
                  onClick={handleEnableAutomation}
                  disabled={!edit_permission()}
                />
              </Grid>
            )}
          </Grid>
          <Divider />
          {/* team name */}
          <Grid
            sx={{ padding: "16px 0px", color: "#3C3E49", fontSize: "14px" }}
          >
            <Grid>
              <span className="mandatoryAsterik">*</span>Name
            </Grid>
            <Grid sx={{ textAlign: "end" }}>
              <FormControl fullWidth>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  sx={{
                    width: "100%",
                    padding: " 0.8rem 0rem 0rem 0rem",
                    "& .MuiOutlinedInput-root": {
                      maxHeight: "36px !important",
                      fontSize: "14px",
                      color: "#3c3e49",
                    },
                  }}
                  type="text"
                  disabled={!edit_permission()}
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                    setDataForPutApi({
                      ...dataForPutApi,
                      name: e.target.value,
                    });
                    setSaveButtonStatus(true);
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>{" "}
          {/* mandatory_custom_fields */}
          <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
            <Grid item xs={4} sx={{ lineHeight: "3rem" }}>
              Mandatory custom fields
            </Grid>

            <Grid sx={{ display: "grid" }} item xs={8}>
              <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                <FormControl fullWidth>
                  <Select
                    multiple
                    displayEmpty
                    disableUnderline
                    disabled={!edit_permission()}
                    IconComponent={ExpandMoreIcon}
                    value={customField}
                    onChange={(e) => {
                      setCustomField(e.target.value);
                      setDataForPutApi({
                        ...dataForPutApi,
                        mandatory_custom_field_ids: extractIds(
                          e.target.value,
                          custom_field_dropdown
                        ),
                      });
                      setSaveButtonStatus(true);
                    }}
                    renderValue={() => "Custom Fields"}
                    MenuProps={MenuProps}
                    onOpen={() => {}}
                    label="Age"
                    sx={{
                      width: "8rem",
                      border: "none",
                      color: "#3C3E49",
                      fontSize: "14px",
                    }}
                    variant="standard"
                  >
                    {custom_field_dropdown.map((name) => (
                      <MenuItem
                        key={name.id}
                        value={name.code}
                        sx={{ padding: "0px" }}
                      >
                        <Checkbox
                          size="small"
                          checked={customField.indexOf(name.code) > -1}
                          sx={{
                            color: "#C0C3CE",
                            "&.Mui-checked": {
                              color: "#7C4DFF",
                            },
                          }}
                        />
                        <ListItemText
                          primary={name.code}
                          sx={{ fontSize: "14px", color: "#3C3E49" }}
                        />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid justifySelf="flex-end" sx={{ height: "2rem" }}>
                {" "}
                <BasicDetailsCustomisedChips
                  customField={customField}
                  handleDelete={handleDelete}
                  edit_permission={edit_permission}
                />
              </Grid>
            </Grid>
          </Grid>{" "}
          {/* click up list id  */}
          <ClickUpList
            component={"teams_form"}
            access_type={access_type}
            spaces={spaces}
            folders={folders}
            lists={lists}
            listsWithoutFolder={listsWithoutFolder}
            fetchSpaceData={fetchSpaceData}
            fetchFolderData={fetchFolderData}
            fetchListData={fetchListData}
            fetchListWithoutFolderData={fetchListWithoutFolderData}
            handleItemClick={handleItemClick}
            openItems={openItems}
            customID={customID}
            setClickupList={handleSetClickupListID}
            textAlign={"end"}
          />
        </Grid>

        {/* Autoclose task*/}
        <Grid className="autoClose">
          <h3 className="sectionHeading">Auto close</h3>
          <Divider />
          <Grid
            sx={{
              color: "#3C3E49",
              fontSize: "14px",
              padding: "1rem 0rem 1rem 0rem",
            }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Auto close tasks
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={autoCloseTask}
                onClick={handleAutoClose}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* Auto close hours */}
          <AutoCloseSection
            autoCloseTask={autoCloseTask}
            autoCloseHours={autoCloseHours}
            access_type={access_type}
            setDataForPutApi={setDataForPutApi}
            setAutoCloseHours={setAutoCloseHours}
            dataForPutApi={dataForPutApi}
          />
        </Grid>

        {/* Natasha integration */}
        <Grid className="natashaIntegration">
          <h3 className="sectionHeading">Natasha integration</h3>
          <Divider />
          <Grid
            sx={{
              color: "#3C3E49",
              fontSize: "14px",
              padding: "1rem 0rem 1rem 0rem",
            }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Enable Natasha integration
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={natashaIntegration}
                onClick={handleNatashaIntegration}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* knowledge base name */}
          <KnowledgeBasenameSection
            natashaIntegration={natashaIntegration}
            edit_permission={edit_permission()}
            knowledgeBasename={knowledgeBasename}
            setKnowledgeBasename={setKnowledgeBasename}
            setDataForPutApi={setDataForPutApi}
            dataForPutApi={dataForPutApi}
          />
        </Grid>

        {/* support level details */}
        <Grid className="supportLevels">
          <h3 className="sectionHeading">Support levels</h3>
          <Divider />
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", paddingTop: "10px" }}
            container
          >
            <Grid item xs={4} sx={{ lineHeight: "3.5rem" }}>
              Choose support levels
            </Grid>

            <Grid sx={{ display: "grid" }} item xs={8}>
              <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                <FormControl fullWidth>
                  <Select
                    IconComponent={ExpandMoreIcon}
                    value={supportLevels}
                    disabled={!edit_permission()}
                    MenuProps={MenuProps}
                    sx={{
                      width: "8rem",
                      border: "none",
                      color: "#3C3E49",
                      fontSize: "14px",
                    }}
                    variant="standard"
                    multiple
                    displayEmpty
                    disableUnderline
                    onChange={handleChangeSupportLevels}
                    renderValue={() => "Support levels"}
                  >
                    {enabledSupportLevels.map((name) => (
                      <MenuItem key={name} value={name} sx={{ padding: "0px" }}>
                        <Checkbox
                          sx={{
                            color: "#C0C3CE",
                            "&.Mui-checked": {
                              color: "#7C4DFF",
                            },
                          }}
                          size="small"
                          checked={supportLevels.indexOf(name) > -1}
                        />
                        <ListItemText primary={name} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid justifySelf="flex-end" sx={{ height: "2rem" }}>
                {" "}
                <Stack direction="row" spacing={1}>
                  {supportLevels.map((field, key) => (
                    <Chip
                      key={field}
                      label={field}
                      sx={{
                        backgroundColor: "#F3EBFF",
                        border: "none",
                        fontSize: "12px",
                      }}
                      variant="outlined"
                      disabled={!edit_permission()}
                      onDelete={(e) => handleDeleteSupportLevels(e, field)}
                      deleteIcon={
                        <CloseIcon
                          disabled
                          sx={{
                            color: "#3C3E49 !important",
                            fontSize: "15px !important",
                          }}
                        />
                      }
                    />
                  ))}
                </Stack>
              </Grid>
            </Grid>
          </Grid>{" "}
          {/* auto escalation */}
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", paddingTop: "1rem" }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Auto escalation
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={autoEscalation}
                onClick={handleAutoEscalation}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* are escalation notes required */}
          <Grid
            sx={{
              color: "#3C3E49",
              fontSize: "14px",
              paddingTop: "1rem",
              paddingBottom: "1rem",
            }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Escalation notes required
              <Tooltip title={tooltip_message.escalation_notes_required}>
                <InfoOutlinedIcon
                  sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                />
              </Tooltip>
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={escalationNotes}
                onClick={handleEscalationNotes}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
        </Grid>

        {/* sub team details */}
        {!["add"].includes(access_type) && (
          <Grid>
            <h3 className="sectionHeading">Sub team</h3>
            <Divider />
            {/* enforce sub team for levels */}
            <Grid
              sx={{ color: "#3C3E49", fontSize: "14px", marginTop: "1rem" }}
              container
            >
              <Grid item xs={4} sx={{ lineHeight: "4rem" }}>
                Enforce sub team for level
                <Tooltip title={tooltip_message.enforce_sub_team_level}>
                  <InfoOutlinedIcon
                    sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                  />
                </Tooltip>
              </Grid>
              <Grid sx={{ display: "grid" }} item xs={8}>
                <Grid justifySelf="flex-end" sx={{ padding: "12px 8px" }}>
                  <FormControl fullWidth>
                    <Select
                      multiple
                      disabled={!edit_permission()}
                      IconComponent={ExpandMoreIcon}
                      value={enforceSubTeam}
                      MenuProps={MenuProps}
                      onOpen={() => {}}
                      onChange={handleChangeSubTeamLevels}
                      renderValue={() => "Support levels"}
                      sx={{
                        width: "9rem",
                        border: "none",
                        color: "#3C3E49",
                        fontSize: "14px",
                      }}
                      variant="standard"
                      displayEmpty
                      disableUnderline
                    >
                      {enabledSupportLevels.map((name) => (
                        <MenuItem
                          key={name}
                          value={name}
                          sx={{ padding: "0px" }}
                        >
                          <Checkbox
                            size="small"
                            checked={enforceSubTeam?.indexOf(name) > -1}
                            sx={{
                              color: "#C0C3CE",
                              "&.Mui-checked": {
                                color: "#7C4DFF",
                              },
                            }}
                          />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <SubTeamLevelChip
                  enforceSubTeam={enforceSubTeam}
                  handleDeleteSubTeamSupportLevels={
                    handleDeleteSubTeamSupportLevels
                  }
                  edit_permission={edit_permission()}
                />
              </Grid>
            </Grid>{" "}
            <SubTeamTableAndText
              enableSubTeam={enforceSubTeam != ""}
              edit_permission={edit_permission}
              setSubTeamcustomField={setSubTeamcustomField}
              setDataForPutApi={setDataForPutApi}
              subTeamcustomField={subTeamcustomField}
              dataForPutApi={dataForPutApi}
              subteamsList={subteamsList}
              handleUpdateOrCreateSubTeam={handleUpdateOrCreateSubTeam}
              page={page}
              deactivateSubTeam={deactivateSubTeam}
              customFieldDropdownValues={customFieldDropdownValues}
              isLoading={isLoading}
              access_type={access_type}
              spaces={spaces}
              folders={folders}
              lists={lists}
              listsWithoutFolder={listsWithoutFolder}
              fetchSpaceData={fetchSpaceData}
              fetchFolderData={fetchFolderData}
              fetchListData={fetchListData}
              fetchListWithoutFolderData={fetchListWithoutFolderData}
              handleItemClick={handleItemClick}
              openItems={openItems}
              customID={customID}
              setClickupList={handleSetClickupListID}
            />
          </Grid>
        )}

        {/* Other preferences */}
        <Grid className="otherPreferences">
          <h3 className="sectionHeading"> Other preferences </h3>
          <Divider />
          {/* send expert email */}
          <Grid
            sx={{
              color: "#3C3E49",
              fontSize: "14px",
              padding: "1rem 0rem 1rem 0rem",
            }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Send expert email
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={sendExpertEmail}
                onClick={handleSendExpertEmail}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* slack channel for queries */}
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", marginBottom: "1rem" }}
            container
          >
            <Grid xs={10} item sx={{ lineHeight: "3rem", display: "flex" }}>
              Slack channel for queries
              <Tooltip title={tooltip_message.slack_channel_queries}>
                <InfoOutlinedIcon
                  sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                />
              </Tooltip>
            </Grid>
            <Grid xs={2} item sx={{ textAlign: "end" }}>
              <TextField
                id="outlined-basic"
                value={slackChanelForQueries}
                onChange={(e) => {
                  setSlackChanelForQueries(e.target.value);
                  setDataForPutApi({
                    ...dataForPutApi,
                    slack_channel_for_queries: e.target.value,
                  });
                  setSaveButtonStatus(true);
                }}
                variant="outlined"
                disabled={!edit_permission()}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "36px !important",
                    fontSize: "14px",
                    color: "#3c3e49",
                  },
                }}
              />
            </Grid>
          </Grid>{" "}
          {/* daily summary notifications */}
          <Grid sx={{ color: "#3C3E49", fontSize: "14px" }} container>
            <Grid xs={10} sx={{ lineHeight: "3rem", display: "flex" }} item>
              Daily summary channel
              <Tooltip title={tooltip_message.daily_summary_channel}>
                <InfoOutlinedIcon
                  sx={{ margin: "0.9rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                />
              </Tooltip>
            </Grid>
            <Grid xs={2} item sx={{ textAlign: "end" }}>
              <TextField
                id="outlined-basic"
                variant="outlined"
                disabled={!edit_permission()}
                value={dailySummaryChannel}
                onChange={(e) => {
                  setDailySummaryChannel(e.target.value);
                  setDataForPutApi({
                    ...dataForPutApi,
                    daily_summary_slack_channel_id: e.target.value,
                  });
                  setSaveButtonStatus(true);
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    maxHeight: "36px !important",
                    fontSize: "14px",
                    color: "#3c3e49",
                  },
                }}
              />
            </Grid>
          </Grid>{" "}
          {/* daily summary frequency */}
          <Grid container sx={{ color: "#3C3E49", fontSize: "14px" }}>
            <Grid
              xs={10}
              item
              justifySelf="flex-end"
              sx={{ lineHeight: "3rem", paddingTop: "0.6rem" }}
            >
              Daily summary notification times
              <Tooltip title={tooltip_message.daily_summary_frequency}>
                <InfoOutlinedIcon
                  sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                />
              </Tooltip>
            </Grid>
            {/* time picker for notification frequency */}
            <Grid xs={2} item sx={{ paddingTop: "1rem", textAlign: "end" }}>
              <CustomTimePicker
                disabled={!edit_permission()}
                views={["hours", "minutes"]}
                sx={{
                  width: "11rem",
                  "& .MuiOutlinedInput-root": {
                    height: "36px",
                    fontSize: "12px",
                  },
                  "& .MuiButtonBase-root": {
                    fontSize: "12px",
                  },
                }}
                placeholder={"Select time"}
                onAccept={onAcceptTimePicker}
              />
              {/* time picker chips */}

              <Grid
                sx={{
                  height: "2rem",
                  justifySelf: "flex-end",
                  paddingTop: "1rem",
                  display: "grid",
                  justifyContent: "flex-end",
                }}
              >
                {" "}
                <BasicDetailsCustomisedChips
                  customField={notificationFrequency}
                  handleDelete={handleDeleteNotificationFreq}
                  edit_permission={edit_permission}
                />
              </Grid>
            </Grid>
          </Grid>{" "}
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", marginTop: "1rem" }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Resolution notes required
              <Tooltip title={tooltip_message.resolution_notes_required}>
                <InfoOutlinedIcon
                  sx={{ margin: "1rem 0rem 0rem 0.1rem", fontSize: "14px" }}
                />
              </Tooltip>
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={enableResolutionNotes}
                onClick={handleEnableResolutionNotes}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* Enable review status */}
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", marginTop: "1rem" }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3rem" }}>
              Enable review status
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={enableReviewStatus}
                onClick={handleEnableReviewStatus}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
          {/* Enable first response notification */}
          <Grid
            sx={{ color: "#3C3E49", fontSize: "14px", paddingTop: "1rem" }}
            container
          >
            <Grid xs={11} item sx={{ lineHeight: "3em" }}>
              Enable first response notification
            </Grid>
            <Grid xs={1} item sx={{ textAlign: "end" }}>
              <Switch
                checked={enableFirstResponseNotification}
                onClick={handleEnableFirstResponseNotification}
                disabled={!edit_permission()}
              />
            </Grid>
          </Grid>{" "}
        </Grid>
      </Grid>

      {/* Footer buttons */}
      <Grid className="footerButtons">
        <Grid className="saveCancelButton">
          <SaveCancel
            handleUserSave={sendDataForPatch}
            saveButtonStatus={saveButtonStatus}
            handleUserCancel={() => {
              window.location.href = `/teams`;
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EditTeamPage;
