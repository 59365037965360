import { useEffect, useRef, useState } from "react";
import { TableWithActionColumn } from "../../components/TableWithActionColumn";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { customFetch } from "../../services/requestServices";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
  isGuardianUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";
import { headCells } from "./data";

const Users = () => {
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(`${baseURL}v1/user/?limit=15`);

  //Initializing abort controllers for users fetch and search fetch calls
  const usersController = useRef(null);
  const searchController = useRef(null);

  const prevFetchUrl = useRef(null);

  const fetchUsersList = async (customUrl) => {
    const fetchUrl = customUrl || url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    if (usersController.current) {
      usersController.current.abort();
    }

    usersController.current = new AbortController();
    const signal = usersController.current.signal;

    setIsLoading(true);
    const usersData = await customFetch(fetchUrl, "GET", signal);

    if (searchController.current) {
      return;
    }
    setRows([...rows, ...usersData.results]);
    setUrl(usersData.next);
    usersController.current = null;
    setIsLoading(false);
  };

  const fetchUsersSearchList = async (searchText) => {
    if (usersController.current) {
      usersController.current.abort();
    }

    if (searchController.current) {
      searchController.current.abort();
    }

    searchController.current = new AbortController();
    const signal = searchController.current.signal;

    setRows([]);
    setIsLoading(true);

    const searchUrl = searchText
      ? `${baseURL}v1/user/?limit=15&search=${searchText}`
      : `${baseURL}v1/user/?limit=15`;

    const usersData = await customFetch(searchUrl, "GET", signal);

    setRows(usersData?.results);
    setUrl(usersData?.next);
    setIsLoading(false);
    searchController.current = null;
  };

  useEffect(() => {
    fetchUsersList();
  }, []);

  return (
    <>
      {isManagerOrSuperUser() || isGuardianUser() ? (
        <TableWithActionColumn
          page={"User"}
          rowdata={rows}
          columndata={headCells}
          isLoading={isLoading}
          fetchUsersSearchList={fetchUsersSearchList}
          fetchData={fetchUsersList}
          url={url}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
    </>
  );
};
export default Users;
