import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";

const successNotify = (successMsg) => {
  toast.success(`${successMsg}`, {
    position: "top-center",
  });
};

const errorNotify = (errorMsg) => {
  toast.error(`${errorMsg}`, {
    position: "top-center",
  });
};

const errorNotifyList = (errorMsgObject) => {
  const display_list = () => (
    <ol className="toastDisplay">
      {errorMsgObject.map((item, index) => (
        <li key={item}>{item}</li>
      ))}
    </ol>
  );
  toast.error(display_list, {
    position: "top-center",
  });
};

export { successNotify, errorNotify, errorNotifyList };
