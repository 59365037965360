import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import "./style.css";
import { isManagerOrSuperUser } from "../../utils/common_functions";
import { CircularLoader } from "../CircularLoader";
import { UsersTable } from "../UsersTable";
import TablesHead from "../TablesHead";
import {
  checkUserShift,
  getSearchParamFromURL,
  initializeSearch,
} from "../../utils/TableWithActionColumnFunctions";
import { useSearchParams } from "react-router-dom";
import SearchField from "../Search";

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:selected": {
    backgroundColor: "#f3ebff",
  },
}));

export const TableWithActionColumn = ({
  rowdata,
  columndata,
  isLoading,
  fetchUsersSearchList,
  fetchData,
  url,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchText, setSearchText] = useState(
    searchParams.get("search") || ""
  );
  const [debouncedValue, setDebouncedValue] = useState("");
  const [hasUserSearched, setHasUserSearched] = useState(false);

  useEffect(() => {
    initializeSearch(
      getSearchParamFromURL,
      setSearchText,
      setHasUserSearched,
      setDebouncedValue
    );
  }, [getSearchParamFromURL]);

  useEffect(() => {
    const sid = setTimeout(() => {
      setDebouncedValue(searchText);
    }, 1000);
    return () => clearTimeout(sid);
  }, [searchText]);

  useEffect(() => {
    if (hasUserSearched) fetchUsersSearchList(debouncedValue);
  }, [debouncedValue]);

  useEffect(() => {
    if (searchText) {
      setSearchParams({ search: searchText });
    } else {
      setSearchParams({});
    }
  }, [searchText, setSearchParams]);

  useEffect(() => {
    if (!isLoading) {
      const tableDivHeight =
        document.getElementsByClassName("tableMainContainer")[0].clientHeight;
      const tableDataHeignt =
        document.getElementsByClassName("usersDataTable")[0].clientHeight;
      // tableDataHeignt > 100 used to avoid empty table case, assuming when data will
      //  be there tableDataHeignt will be more than 100
      if (tableDataHeignt > 100 && tableDataHeignt <= tableDivHeight) {
        fetchData();
      }
    }

    const handleScroll = (e) => {
      const clientHeight = e.target.clientHeight;
      const scrollTop = e.target.scrollTop;
      const scrollHeight = e.target.scrollHeight;
      const sum = Math.trunc(clientHeight + scrollTop - scrollHeight);
      if (sum >= -100 && isLoading === false) {
        fetchData();
      }
    };

    const elem = document.getElementsByClassName("tableMainContainer")[0];
    elem.addEventListener("scroll", handleScroll);

    return () => elem.removeEventListener("scroll", handleScroll);
  }, [isLoading]);

  const handleAddUser = (event) => {
    window.location.href = "/users/add-user/?user=new-user";
  };
  const getQueryParamsForEditUser = (row) => {
    return `name=${row.id}`;
  };
  const handleEditUser = (event, row) => {
    const queryParamsForEditUser = getQueryParamsForEditUser(row);
    window.location.href = `/users/edit-user/?${queryParamsForEditUser}`;
  };

  const handleChangeShift = (event, row) => {
    const userID = `?userid=${row.id}`;
    const shiftID = row.user_shift.id
      ? `&userShiftId=${row.user_shift.id}`
      : "";

    window.location.href = `/users/change-shift/${userID}${shiftID}`;
  };

  const handleInputChange = (value) => {
    setHasUserSearched(true);
    setSearchText(value);
  };

  const handleClearSearch = () => {
    setSearchText("");
  };

  return (
    <Grid
      sx={{
        paddingLeft: "1em",
        overflow: "hidden",
        paddingRight: "20px",
        width: "95%",
        maxHeight: "100vh",
        backgroundColor: "transparent",
      }}
    >
      <Grid
        container
        spacing={2}
        style={{
          alignItems: "center",
        }}
      >
        <Grid item className="teamHeading" xs={6}>
          Users
        </Grid>

        <Grid
          item
          container
          xs={6}
          sx={{
            justifyContent: "end",
          }}
        >
          <Grid item>
            <SearchField
              inputValue={searchText}
              handleInputChange={handleInputChange}
              heading={<span>user</span>}
              handleClearSearch={handleClearSearch}
              buttonDisplay={isManagerOrSuperUser() ? "" : "none"}
              buttonTitle="Add user"
              handleAdd={handleAddUser}
            />
          </Grid>
        </Grid>
      </Grid>

      <TableContainer sx={{ maxHeight: "87vh" }} className="tableMainContainer">
        <Table
          className="usersDataTable"
          stickyHeader
          aria-label="sticky table"
          size="small"
          sx={{ tableLayout: "auto" }}
        >
          <TablesHead headCells={columndata} />
          <TableBody style={{ zIndex: 0 }}>
            {rowdata.map((row, index) => {
              return (
                <UsersTable
                  row={row}
                  key={row.id}
                  handleEditUser={handleEditUser}
                  handleChangeShift={handleChangeShift}
                  index={index}
                  checkUserShift={checkUserShift}
                />
              );
            })}
            {isLoading && (
              <StyledTableRow>
                <TableCell
                  align="left"
                  className="tableCells"
                  padding="normal"
                  colSpan={columndata.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  <CircularLoader />
                </TableCell>
              </StyledTableRow>
            )}
            {!url && !isLoading && (
              <StyledTableRow>
                <TableCell
                  align="left"
                  className="tableCells"
                  padding="normal"
                  colSpan={columndata.length}
                  style={{
                    textAlign: "center",
                  }}
                >
                  No more data
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
