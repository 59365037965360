import React from "react";
import "./style.css";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export const CustomUI = ({
  src,
  title,
  closeButtonTitle,
  functionalButtonTitle,
  handleClick,
}) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <div className="container">
      <Button onClick={handleOpen} style={{ textTransform: "none" }}>
        <div className="btn-icon">{src}</div>
        <div className="text">{title}</div>
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 470,
            height: 220,
            bgcolor: "background.paper",
            p: 4,
            borderRadius: "0.5rem",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 600,
            }}
          >
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Are you sure you want to log out of guardian. You will need to log
            back in next time to track your tickets.
          </Typography>
          <div className="btn-container">
            <Button
              data-testid="goBack-btn"
              variant="contained"
              sx={{
                background: " var(--Base-Grey-3, #EEE)",
                color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
                fontWeight: "700",
                "&:hover": {
                  background: " var(--Base-Grey-3, #D6D6D6)",
                },
              }}
              onClick={handleClose}
            >
              {closeButtonTitle}
            </Button>

            <Button
              data-testid="logout-btn"
              variant="contained"
              sx={{
                background: " var(--Interactive-Bg-Err, #FF8A80)",
                color: "var(--True-Grey-9, var(--Base-Grey-9, #262626))",
                fontWeight: "700",
                "&:hover": {
                  background: " var(--Interactive-Bg-Err, #FF9F97)",
                },
              }}
              onClick={handleClick}
            >
              {functionalButtonTitle}
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};
