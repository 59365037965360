export const defaultUserSelectedFilter = {
  assignees: [],
  status: [],
  teams: [],
  sub_teams: [],
  "Custom date": {
    From: "",
    To: "",
  },
  Today: {
    From: "",
    To: "",
  },
  Yesterday: {
    From: "",
    To: "",
  },
  "Last 7 days": {
    From: "",
    To: "",
  },
  "Last 14 days": {
    From: "",
    To: "",
  },
  "Last 30 days": {
    From: "",
    To: "",
  },
  spanKey: "",
  startDate: "",
  endDate: "",
  sla_status: [],
};
