import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import DashboardBarChart from "../DashboardBarChart";
import { MaterialTable } from "../MaterialTable";

const style = {
  position: "absolute",
  display: "flex",
  flexDirection: "column",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "85%",
  height: "95%",
  bgcolor: "background.paper",
  p: 4,
  borderRadius: "0.5rem",
};

const DashboardChartModal = ({ chartProps, tableProps, isDataEmpty }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      {!isDataEmpty ? (
        <>
          <Button onClick={handleOpen}>
            <OpenInNewOutlinedIcon />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <DashboardBarChart
                {...chartProps}
                style={{
                  height: "35vh",
                }}
                open={open}
                handleClose={handleClose}
              />
              <MaterialTable {...tableProps} />
            </Box>
          </Modal>{" "}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default DashboardChartModal;
