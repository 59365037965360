import React, { useState } from "react";
import ListSubheader from "@mui/material/ListSubheader";
import List from "@mui/material/List";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { DynamicList, RenderList } from "../DynamicList";
import { SaveCancel } from "../SaveCancel";
import ClickupSymbolLogo from "../../assets/ClickupSymbolLogo.png";
import "./style.css";

const NestedList = ({
  onSelectList,
  spaces,
  folders,
  lists,
  listsWithoutFolder,
  fetchSpaceData,
  fetchFolderData,
  fetchListData,
  fetchListWithoutFolderData,
  handleItemClick,
  openItems,
  disableCondition,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedList, setSelectedList] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleListSelect = (list) => {
    if (selectedList === list.id) {
      setSelectedList(null);
    } else {
      setSelectedList(list.id);
    }
  };

  const handleSave = () => {
    onSelectList(selectedList);
    handleClose();
    setSelectedList(null);
  };

  const handleCancel = () => {
    setSelectedList(null);
    handleClose();
    setSelectedList(null);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        disabled={disableCondition}
        sx={{
          position: "absolute",
          top: "34%",
          right: "0%",
        }}
        className={disableCondition ? "disabledButton" : ""}
      >
        <img src={ClickupSymbolLogo} alt={"link"} className="clickupLogo" />
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          overflow: "hidden",
        }}
      >
        <div className="list">
          <List
            sx={{
              padding: "0",
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
            component="nav"
          >
            <ListSubheader
              id="nested-list-subheader"
              sx={{ paddingTop: "1rem", fontSize: "16px" }}
            >
              ClickUp List
            </ListSubheader>
            <DynamicList
            key={0}
              items={spaces || []}
              fetchItems={() => fetchSpaceData()}
              handleItemClick={handleItemClick}
              openItems={openItems}
              dataType="spaces"
              renderItem={(space) => (
                <>
                  <DynamicList
                    key={space.name}
                    sx={{ marginLeft: "2rem" }}
                    items={folders[space.id] || []}
                    fetchItems={() => fetchFolderData(space.id)}
                    handleItemClick={handleItemClick}
                    openItems={openItems}
                    dataType="folders"
                    renderItem={(folder) => (
                      <DynamicList
                        key={folder.name}
                        sx={{ marginLeft: "2rem" }}
                        items={lists[folder.id] || []}
                        fetchItems={() => fetchListData(folder.id)}
                        handleItemClick={handleItemClick}
                        openItems={openItems}
                        dataType="lists"
                        renderItem={(list) => (
                          <RenderList
                            key={list.name}
                            onClick={() => handleListSelect(list)}
                            selectedList={selectedList}
                            list={list}
                          />
                        )}
                      />
                    )}
                  />

                  <DynamicList
                    key={space.id}
                    sx={{ marginLeft: "2rem" }}
                    items={listsWithoutFolder[space.id] || []}
                    fetchItems={() => fetchListWithoutFolderData(space.id)}
                    handleItemClick={handleItemClick}
                    openItems={openItems}
                    dataType="lists"
                    renderItem={(list) => (
                      <RenderList
                        key={list.id}
                        onClick={() => handleListSelect(list)}
                        selectedList={selectedList}
                        list={list}
                      />
                    )}
                  />
                </>
              )}
            />
          </List>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <SaveCancel
              saveButtonStatus={selectedList}
              handleUserSave={handleSave}
              handleUserCancel={handleCancel}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default NestedList;
