export const seriesMappingTeam = {
  l1: { label: "Tickets closed by L1", color: "#D9C4FF" },
  l2: { label: "Tickets closed by L2", color: "#8C9EFF" },
  l3: { label: "Tickets closed by L3", color: "#E2FCEA" },
  internal: { label: "Tickets auto closed", color: "#CE8CF8" },
  external: {label: "Tickets closed by External", color: "#96C9F4"}
};

export const keys = Object.keys(seriesMappingTeam);
export const colors = keys.map((key) => seriesMappingTeam[key].color);
export const legends = [
  {
    dataFrom: "keys",
    anchor: "bottom-left",
    direction: "row",
    justify: false,
    translateX: 0,
    translateY: 50,
    itemsSpacing: 70,
    itemWidth: 100,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
    data: keys.map((key) => ({
      id: key,
      label: seriesMappingTeam[key].label,
      color: seriesMappingTeam[key].color,
    })),
  },
];

export const valueFormatter = (value) => `${value}%`;

export const keysStatus = [
  "Pending",
  "L1-Pickup",
  "L2-Pickup",
  "L3-Pickup",
  "In-Progress",
  "Reassign",
  "Pending-Dependency",
  "Review",
];

export const colorsStatus = [
  "#7FC7BC",
  "#D9C4FF",
  "#AFB2C0",
  "#B9F6CA",
  "#8C9EFF",
  "#FF8A80",
  "#FFE57F",
  "#96C9F4",
];

export const valueFormatterStatus = (value) => `${value}d`;

export const keysSquad = ["L1", "L2", "L3"];

export const colorsSquad = ["#D9C4FF", "#AFB2C0", "#B9F6CA"];
