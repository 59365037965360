export const updatedObject = (originalObject) =>
  Object.entries(originalObject).reduce((acc, [key, value]) => {
    acc[key] = value == null || value == undefined ? "" : value;
    return acc;
  }, {});

export const isSuperUser = () => {
  return localStorage.getItem("is_superuser") === "true";
};

export const isManagerOrSuperUser = () => {
  return (
    localStorage.getItem("is_manager") == "true" ||
    localStorage.getItem("is_superuser") == "true"
  );
};

export const isGuardianUser = () => {
  return !(
    localStorage.getItem("guardian_user_id") == 0 ||
    localStorage.getItem("guardian_user_id") == null ||
    localStorage.getItem("guardian_user_id") == "null"
  );
};
export const showAddButton = (page) => {
  if (page === "Teams") return isSuperUser();
  else if (page == "User") return isManagerOrSuperUser();
};

export const redirectToTicketSumary = () => {
  if (!isGuardianUser()) {
    window.location.href = "/tickets";
  } else window.location.href = "/dashboard";
};

export const arrayToUpperCase = (myArray) => {
  if (myArray) {
    return myArray.map((item) => item.toUpperCase());
  }
};

export const arrayToLowerCase = (myArray) => {
  if (myArray) {
    return myArray.map((item) => item.toLowerCase());
  }
};

export const getTicketsUrl = (url, teams) => {
  let teamsQueryParam = "";
  if (isSuperUser()) return url;
  else if (isGuardianUser() && !isSuperUser() && teams) {
    teamsQueryParam = localStorage
      .getItem("teams")
      .split(",")
      .map((team) => `&teams=${team}`)
      .join("")
      .replace("&", "?");
  }
  return `${url}/${teamsQueryParam}`;
};

export const getTicketsLink = (link) => {
  return link.includes("/tickets")
    ? getTicketsUrl("/tickets", localStorage.getItem("teams"))
    : link;
};

export const createHeadCellObject = (id, numeric, disablePadding, label) => {
  return {
    id,
    numeric,
    disablePadding,
    label,
  };
};

export const formatTimeValue = (timeValue) => {
  const hours =
    parseInt(timeValue.$H) < 10 ? `0${timeValue.$H}` : `${timeValue.$H}`;
  const mins =
    parseInt(timeValue.$m) < 10 ? `0${timeValue.$m}` : `${timeValue.$m}`;
  const secs =
    parseInt(timeValue.$s) < 10 ? `0${timeValue.$s}` : `${timeValue.$s}`;

  return `${hours}:${mins}:${secs}`;
};

export const updateDateToHumanFormat = (dateToFormat) => {
  const formattedDate = new Date(dateToFormat);
  const day = formattedDate.getUTCDate().toString().padStart(2, "0");
  const month = (formattedDate.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = formattedDate.getUTCFullYear().toString().slice(-2);
  const hours = formattedDate.getUTCHours().toString().padStart(2, "0");
  const minutes = formattedDate.getUTCMinutes().toString().padStart(2, "0");
  return (
    <div>
      <div>{`${day}/${month}/${year}`}</div>
      <div>{`${hours}:${minutes}`}</div>
    </div>
  );
};

export const getDateParamInUrl = (userSelectedFilter) => {
  const locationObject = new URLSearchParams(window.location.search);
  const dateMapping = {
    today: "Today",
    yesterday: "Yesterday",
    last_7_days_from: "Last 7 days",
    last_14_days_from: "Last 14 days",
    last_30_days_from: "Last 30 days",
    custom_date_from: "Custom date",
    custom_date_to: "Custom date",
  };
  const presentParam = Object.keys(dateMapping).find((param) =>
    locationObject.has(param)
  );

  if (presentParam === undefined || locationObject.size == 0) {
    return userSelectedFilter["spanKey"];
  } else if (presentParam) {
    return dateMapping[presentParam];
  } else {
    return "Last 7 days";
  }
};

export const getMinMaxDate = (dateString, noOfdays) => {
  const date = new Date(dateString);

  date.setDate(date.getDate() + noOfdays);

  return date;
};

const fixedList = [
  "div-20",
  "img-10",
  "span-10",
  "parent_filter_option",
  "filter_dropdown_child_options",
  "searchInput",
  "firstLetter",
  "assignee_sub_filter",
  "sub_filter_checkbox",
  "single_selection_sub_filter",
];

export const handleClickOutsideFilters = (dropdownClassName) => {
  const elem = document.getElementsByClassName(dropdownClassName)[0];

  const handleHideAllFilters = (e) => {
    if (!fixedList.includes(Array.from(e.target.classList)[0])) {
      elem?.classList.remove("show");
    }
  };

  document.addEventListener("click", handleHideAllFilters);

  return () => {
    document.removeEventListener("click", handleHideAllFilters);
  };
};
