export const generateToolTipTitle = (cardType) => {

    if(cardType==="Breached")
    {
      return 'We were unable to resolve these tickets in the agreed SLA, we are actively working on these tickets and expect to be able to provide a resolution soon.'
    }
    else if(cardType==="On Track")
    {
      return 'We are actively working on these tickets and expected them to be resolved within the agreed SLA.'
    }
    else if(cardType==="At Risk")
    {
      return 'We may not be able to resolve these tickets in the agreed SLA. We are actively working on these tickets and expect to be able to provide a resolution soon.'
    }
    else if(cardType==="Paused")
    {
      return "Tickets that are awaiting feedback from the issue raiser. Tickets will be auto closed if feedback is not provided."
    }
    else if(cardType==="Achieved")
    {
      return "We have resolved the issue within the agreed SLA.";
    }
    else if(cardType==="First response SLA")
    {
      return "Tickets where support engineers posted a response (non automated) on the support ticket in the agreed first response SLA";
    }
  }

export const removeParamFromURL = (url,param) => {
  const regex = new RegExp(`[?&]${param}=([^$#]+)(&|$)`);
  const newUrl = url.replace(regex,(match,value,separator)=>separator === '&' ? separator : '');
 
  return newUrl;
}

export const removeDateParamsFromUrl = (url,dateStartParam,dateEndParam) => {
  const paramWithoutDateStart = removeParamFromURL(url,dateStartParam);
  const paramWithoutDateEnd = removeParamFromURL(paramWithoutDateStart,dateEndParam);

  return paramWithoutDateEnd;
}