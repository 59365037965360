import React from "react";
import LoginPage from "../Login";
import MainApp from "../App";
import { isAuthenticated } from "../../utils/constants";

const Home = () => {
  return (
    <div className="Home">{isAuthenticated ? <MainApp /> : <LoginPage />}</div>
  );
};

export default Home;
