import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const CircularLoader = ({sx}) => {
  return (
    <CircularProgress
      sx={{
        color: "#6200ea",
        ...sx
      }}
    />
  );
};
