import * as React from "react";
import { useState } from "react";
import { Chip, Menu, MenuItem } from "@mui/material";

export const ChipWithMenu = ({ menuItemList, count }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const menu = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      sx={{
        "& .MuiMenu-paper": {
          maxHeight: "15rem",
          overflow: "auto",
          fontSize: "14px",
          borderRadius: "10px",
        },
      }}
    >
      {menuItemList.map((item, index) => {
        return (
          <MenuItem
            onClick={handleClose}
            key={item}
            sx={{
              color: "#3C3E49",
              fontFamily: "Rubik,sans-serif",
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "400",
              lineHeight: "1.25rem",
              letterSpacing: "0.00875rem",
              "&:hover": { backgroundColor: "transparent" },
            }}
          >
            {item}
          </MenuItem>
        );
      })}
    </Menu>
  );

  return (
    <div onMouseEnter={handleClick}>
      <Chip
        label={`+${count}`}
        size="small"
        sx={{
          borderRadius: "2rem",
          border: "1px solid var(--Primary-Border, #BCB1F5)",
          background: "#F7F5FF",
          fontWeight: "400",
        }}
      />
      {menu}
    </div>
  );
};
