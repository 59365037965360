import PropagateLoader from "react-spinners/PropagateLoader";

export const Loader = ({ loading, color, size }) => {
  return (
    <PropagateLoader
      color={color}
      loading={loading}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}


