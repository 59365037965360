export const allWorkingDays = [
    { id: 1, text: "Monday", checked: false },
    { id: 2, text: "Tuesday", checked: false },
    { id: 3, text: "Wednesday", checked: false },
    { id: 4, text: "Thursday", checked: false },
    { id: 5, text: "Friday", checked: false },
    { id: 6, text: "Saturday", checked: false },
    { id: 7, text: "Sunday", checked: false },
  ];

  export const dataToBeSaved = {
    new_working_days: [],
    new_working_shift: {
      start_time: "",
      end_time: "",
    },
  };