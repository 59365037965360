import * as React from "react";
import AddUserPage from "../../components/AddUserPage";
import { ToastContainer } from "react-toastify";
import {
  successNotify,
  errorNotifyList,
} from "../../components/ToastComponent";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
  isGuardianUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";

const AddUser = () => {
  const location = window.location.search;
  const receivedValue = location.split("=")[1];
  let page = "";
  if (receivedValue !== "new-user") {
    page = "Edit user-" + receivedValue;
  } else {
    page = "Add guardian user";
  }

  return (
    <>
      {(isManagerOrSuperUser() || isGuardianUser() ) ? (
        <AddUserPage
          page={page}
          errorNotifyList={errorNotifyList}
          successNotify={successNotify}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
      <ToastContainer autoClose={2000} />
    </>
  );
};
export default AddUser;
