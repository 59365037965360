import { Tooltip } from "@mui/material";
import { Card } from "../DashboardCards";

const OpenTickets = ({
  isLoading,
  getLoader,
  openTicketsError,
  allCardsData,
  totalCount,
  parameterString,
}) => {
  return (
    <div className="dashbaord_cards_container">
      <div className="dashboard_cards_heading">
        Open tickets SLA status
        <span className="view_disclaimer">
        <Tooltip
          title="Open tickets aren't affected by date filter"
          placement="bottom"
          arrow
          sx={{
            "& .MuiTooltip-tooltip": {
              marginTop: "0px !important",
              borderRadius: "0.1875rem",
              background: "var(--Base-Blue-grey-10, #3C3E49)",
            },
          }}
        >
          View disclaimer
        </Tooltip>
        </span>
        </div>
      {isLoading && getLoader()}
      {!openTicketsError && !isLoading && (
        <div className="dashboard_cards_row">
          {allCardsData.map((cardInfo, i) => {
            return (
              <Card
                key={`${i}-${cardInfo.name}`}
                cardIndex={i}
                cardInfo={cardInfo}
                totalCount={totalCount}
                parameterString={parameterString}
                shouldClickBeDisabled={false}
                closedOrOpen="open"
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default OpenTickets;
