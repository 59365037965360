import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

const TrackPageViews = () => {
    let location = useLocation();

    useEffect(()=>{
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    },[location]);

    return null;
  }

export default TrackPageViews;