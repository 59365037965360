import { useState, useEffect, useRef } from "react";
import { REACT_APP_API_PATH as baseURL } from "../../config";
import { customFetch } from "../../services/requestServices";
import {
  redirectToTicketSumary,
  isManagerOrSuperUser,
} from "../../utils/common_functions";
import { ErrorComponent } from "../Error";
import TableWithExpandableRows from "../../components/TableWithExpandableRows";

function Teams() {
  const [rowData, setRowData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState(`${baseURL}v1/team/?limit=15`);

  const teamsController = useRef(null);
  const searchController = useRef(null);

  const prevFetchUrl = useRef(null);

  const fetchData = async (customUrl) => {
    const fetchUrl = customUrl || url;
    if (fetchUrl === prevFetchUrl.current || !fetchUrl) return;
    prevFetchUrl.current = fetchUrl;

    if (teamsController.current) {
      teamsController.current.abort();
    }

    teamsController.current = new AbortController();
    const signal = teamsController.current.signal;

    setIsLoading(true);
    const data = await customFetch(fetchUrl, "GET", signal);

    if (searchController.current) {
      return;
    }
    if (data.next != null) setUrl(data.next);
    else setUrl("");
    setRowData([...rowData, ...data.results]);
    teamsController.current = null;
    setIsLoading(false);
  };

  const fetchTeamsSearchList = async (searchText) => {
    if (teamsController.current) {
      teamsController.current.abort();
    }

    if (searchController.current) {
      searchController.current.abort();
    }

    searchController.current = new AbortController();
    const signal = searchController.current.signal;

    setRowData([]);
    setIsLoading(true);

    const searchUrl = searchText
      ? `${baseURL}v1/team/?limit=15&search=${searchText}`
      : `${baseURL}v1/team/?limit=15`;

    const searchTeamData = await customFetch(searchUrl, "GET", signal);
    
    setRowData(searchTeamData?.results);
    setUrl(searchTeamData?.next);
    setIsLoading(false);
    searchController.current = null;
  };

  useEffect(() => {
    fetchData(url);
  }, []);

  const fetchMoreOnBottomReached = () => {
    if (url != "") fetchData(url);
  };
  return (
    <>
      {isManagerOrSuperUser() ? (
        <TableWithExpandableRows
          teamsData={rowData}
          isLoading={isLoading}
          fetchTeamsSearchList={fetchTeamsSearchList}
          fetchMoreOnBottomReached={fetchMoreOnBottomReached}
        />
      ) : (
        <ErrorComponent
          errorMessage="You do not have access to view this page"
          handleRefresh={redirectToTicketSumary}
          firstButtonText="Go back"
        />
      )}
    </>
  );
}
export default Teams;
